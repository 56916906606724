
<template>
  <div id="user-edit-profile">
    <h6 class="mb-3 text-uppercase bg-light p-2">
      Basic Info
    </h6>
    <div class="row gx-3 gy-3">
      <div class="col-md-6">
        <label class="form-label" for="firstName">First name</label>
        <input id="firstName" type="text" class="form-control" v-model="data.first_name"
        :class="{ 'is-invalid': v$.data.first_name.$error }" placeholder="First name" name="first_name" />
        <div v-for="(item, index) in v$.data.first_name.$errors"
          :key="index" class="invalid-feedback">
          <span v-if="item.$message">{{ item.$message }}</span>
        </div>
      </div>
      <div class="col-md-6">
        <label class="form-label" for="middleName">Middle name</label>
        <input id="middleName" type="text" class="form-control" v-model="data.middle_name"
         placeholder="Middle name" name="middle_name" />
      </div>
      <div class="col-md-6">
        <label class="form-label" for="LastName">Last name</label>
        <input id="LastName" type="text" class="form-control" v-model="data.last_name"
        :class="{ 'is-invalid': v$.data.last_name.$error }"  placeholder="Last Name" name="last_name" />
        <div v-for="(item, index) in v$.data.last_name.$errors"
          :key="index" class="invalid-feedback">
          <span v-if="item.$message">{{ item.$message }}</span>
        </div>
      </div>
      <div class="col-md-6">
          <label class="form-label" for="inputEmail">Email Address</label>
          <input disabled id="inputEmail" :readonly="!!data.is_verified" label="Email" v-model="data.email"
          :class="{ 'is-invalid': v$.data.email.$error }" type="email" name="email" class="form-control"/>
          <div v-for="(item, index) in v$.data.email.$errors"
            :key="index" class="invalid-feedback">
            <span v-if="item.$message">{{ item.$message }}</span>
          </div>  
      </div>
      <div class="col-md-6">
        <label class="form-label" for="inputMobile">Mobile</label>
        <input id="inputMobile" autocomplete="false" type="text" class="form-control" v-model="data.mobile"
          :class="{ 'is-invalid': v$.data.mobile.$error  }" placeholder="mobile" name="mobile" />
        <div v-for="(item, index) in v$.data.mobile.$errors"
          :key="index" class="invalid-feedback">
          <span v-if="item.$message">{{ item.$message }}</span>
        </div>
      </div>
      <div class="col-md-6">
        <label for="gender" class="form-label">Gender</label>
        <select v-model="data.gender" name="gender" id="gender" class="form-select"
        :class="{ 'is-invalid': v$.data.gender.$error }" >
          <option disabled :value="null" >--select gender--</option>
          <option v-for="(gender, gdIndex) in genderOptions" :key="gdIndex" 
            :value="gender.value">{{gender.label}}</option>
        </select>
        <div v-for="(item, index) in v$.data.gender.$errors"
          :key="index" class="invalid-feedback">
          <span v-if="item.$message">{{ item.$message }}</span>
        </div> 
      </div>
    </div>
    <div class="row my-3">
      <div class="col-12">
        <label class="form-label" for="ProfileImage">Change profile photo</label>
        <profile-image-uploader @uploadSuccess="updateImage($event)"/>
      </div>
    </div>
    <div class="row justify-content-end">
      <div class="col-12">
        <div class="text-end">
          <button type="button" @click.prevent="updateUser()" class="btn btn-primary mt-2">
            Save Changes
          </button>
        </div>
      </div>
    </div>
</div>
</template>

<script>
import { required, email, helpers } from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";
import ProfileImageUploader from '@/components/forms/ProfileImageUploader.vue';

export default {
  setup() {
    return { v$: useVuelidate() };
  },
  components:{
    ProfileImageUploader
  },
  data() {
    return {
      genderOptions: [
        { label: 'Male', value: 'male' },
        { label: 'Female', value: 'female' },
        { label: 'Others', value: 'others' }
      ],
      data: {
        first_name: "",
        middle_name: "",
        last_name: "",
        email: "",
        mobile: "",
        gender: null,
        image: "",
      }
    }
  },
  validations: {
    data:{
      first_name: {
        required: helpers.withMessage("First name is required", required),
      },
      last_name: {
        required: helpers.withMessage("Last name is required", required),
      },
      email: {
        required: helpers.withMessage("Email is required", required),
        email: helpers.withMessage("Please enter valid email", email),
      },
      mobile: {
        required: helpers.withMessage("Phone number is required", required),
      },
      gender: {
        required: helpers.withMessage("Gender is required", required),
      }
    }
  },
  computed: {
    activeUser(){
      return this.$store.state.AppActiveUser
    }
  },
  methods: {
    updateUser() {
      this.v$.$validate().then(result =>{
        if (!result) return;
        const formData = new FormData()
        formData.append('data', JSON.stringify(this.data))
        this.$store.dispatch("changeLoaderValue", true)
        this.$http.post('/account/update',formData)
        .then((response) => {
            this.$store.dispatch("changeLoaderValue", false)
            if(response.data.success){
              this.$store.dispatch("fetchActiveUser");
            }
        })
      })
    },
    patchUser(){
      this.data = this.$filters.patchUpdatables(this.data, this.activeUser)
    },
    updateImage(image){
      this.data.image = image
      this.updateUser()
    }, 
  },
  created(){
    this.patchUser()
  },
}
</script>
